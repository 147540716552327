$black: #303030;
$gray-dark: #515151;
$gray: #949494;
$gray-light: #D7D7D7;
$white: #F8F8F8;

$purple-light: #c789fe;
$purple: #9013FE;
$purple-dark: #560b98;

$blue-light: #9edbee;
$blue: #3eb7dd;
$blue-dark: #2b809a;

:export {
  black: $black;
  graydark: $gray-dark;
  gray: $gray;
  graylight: $gray-light;
  white: $white;
  purplelight: $purple-light;
  purple: $purple;
  purpledark: $purple-dark;
  bluelight: $blue-light;
  blue: $blue;
  bluedark: $blue-dark;
}
