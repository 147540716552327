@import '../../../styles/colors';

.button {
  display: inline-block;
  font-weight: 700;
  transition: all 0.2s;
  cursor: pointer;
  border: 0 none;
  border-radius: 6px;
  opacity: 1;
  &:active,
  &:focus {
    outline: none;
  }
  &.disabled {
    cursor: default;
  }
}

.small {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.5px;
  height: 32px;
  min-height: 32px;
  padding: 0 0.6em;
}

.medium {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
  height: 40px;
  min-height: 40px;
  padding: 0 1em;
}

.large {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  height: 48px;
  min-height: 48px;
  padding: 0 3em;
}

.purple {
  background-color: $purple;
  color: $white;
  &:enabled{
    &:hover {
      background-color: $purple-light;
      box-shadow: 0 2px 12px 0 rgba(189, 193, 195, 0.6);
    }
    &:active {
      background-color: $purple-dark;
    }
  }
  &.disabled {
    background-color: $gray-dark;
    color: $gray;
  }
  &.neon {
    border: 1px solid $purple-light;
    background-color: transparent;
    color: $purple-light;
    box-shadow:
      0 0 4px $white;
    text-shadow: 0 0 12px $white;
    @media only screen and (max-width: 600px) {
      background-color: transparent;
      border-color: $white;
      color: $white;
      box-shadow:
        0 0 6px $purple,
        0 0 6px $purple inset,
        0 0 12px $purple-light inset,
        0 0 12px $white;
      text-shadow:
        0 0 6px $purple,
        0 0 12px $purple,
        0 0 18px $white;
    }
    &:enabled {
      &:hover {
        background-color: transparent;
        border-color: $white;
        color: $white;
        box-shadow:
          0 0 6px $purple,
          0 0 6px $purple inset,
          0 0 12px $purple-light inset,
          0 0 12px $white;
        text-shadow:
          0 0 6px $purple,
          0 0 12px $purple,
          0 0 18px $white;
        animation: hover-flicker 0.1s linear 0s infinite;
      }
      &:active {
        animation: none;
        background-color: $purple-dark;
      }
    }
    &.disabled {
      color: $gray-dark;
      border-color: $gray-dark;
      box-shadow: none;
      text-shadow: none;
    }
  }
}

.blue {
  background-color: $blue;
  color: $white;
  &:enabled{
    &:hover {
      background-color: $blue-light;
      box-shadow: 0 2px 12px 0 rgba(189, 193, 195, 0.6);
    }
    &:active {
      background-color: $blue-dark;
    }
  }
  &.disabled {
    background-color: $gray-dark;
    color: $gray;
  }
  &.neon {
    border: 1px solid $blue-light;
    background-color: transparent;
    color: $blue-light;
    box-shadow: 0 0 6px $white;
    text-shadow: 0 0 12px $white;
    @media only screen and (max-width: 600px) {
      background-color: transparent;
      border-color: $white;
      color: $white;
      box-shadow:
        0 0 6px $blue,
        0 0 6px $blue inset,
        0 0 12px $blue-light inset,
        0 0 12px $white;
      text-shadow:
        0 0 6px $blue,
        0 0 12px $blue,
        0 0 18px $white;
    }
    &:enabled {
      &:hover {
        background-color: transparent;
        border-color: $white;
        color: $white;
        box-shadow:
          0 0 6px $blue,
          0 0 6px $blue inset,
          0 0 12px $blue-light inset,
          0 0 12px $white;
        text-shadow:
          0 0 6px $blue,
          0 0 12px $blue,
          0 0 18px $white;
        animation: hover-flicker 0.1s linear 0s infinite;
      }
      &:active {
        animation: none;
        background-color: $blue-dark;
      }
    }
    &.disabled {
      color: $gray-dark;
      border-color: $gray-dark;
      box-shadow: none;
      text-shadow: none;
    }
  }
}

.block {
  display: block;
  width: 100%;
}

@keyframes hover-flicker {
  50% { opacity: 0.9; }
  100% { opacity: 0.95; }
}
