@import '../../../styles/colors';

.container {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.mobile {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
    max-width: 240px;
    margin: 20px auto 40px auto;
    border-radius: 6px;
    padding-left: 16px;
  }
}

.option {
  width: 100%;
  background-color: $blue;
}
