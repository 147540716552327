@import '../../../styles/colors';

.container {
  padding: 16px 16px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: hidden;
  pointer-events: all;
  outline: none;
}
.container:hover {
  opacity: 0.7;
}
.container.active:hover {
  opacity: 0.7;
}
.container.active .inner,
.container.active .inner::before,
.container.active .inner::after {
  background-color: #fff;
}

.outer {
  width: 32px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: background-color 0.125s 0.175s ease-in;
}

.inner, .inner::before, .inner::after {
  position: absolute;
  width: 32px;
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
}
.inner::before, .inner::after {
  content: '';
  display: block;
}

.inner::before {
  top: -8px;
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.inner::after {
  top: 8px;
  right: 0;
  bottom: -8px;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.active .inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.active .inner::before {
  left: -64px;
  top: -64px;
  transform: translate3d(64px, 64px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.active .inner::after {
  right: -64px;
  top: -64px;
  transform: translate3d(-64px, 64px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
