.container {
  position: relative;
}

.content {
  position: relative;

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }

  section {
    position: relative;
    overflow: hidden;
    &:not(last-child) {
      margin-bottom: 2em;
    }
  }
}

.header {}
.overview {}
.professional {}
.personal {}
.games {}
.source {}

.face {
  width: 128px;
  height: 128px;
  float: right;
}

.details {
  ul {
    list-style-type: none;
  }

  li {
    margin: 12px 0;

    h3, h4, h5 {
      font-style: italic;
    }
  }
}

.game-links {
  text-align: center;
  button {
    margin: 8px;
  }
}

.nintendo {
  text-align: center;
  font-size: calc(max(min(6vw, 50px), 18px));
}

.friendcode {
  font-weight: 700;
}
