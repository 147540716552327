@import '../../../styles/colors.scss';

.container {
  user-select: none;
}

.primary {
  font-family: "Neo-Navy 3D";
  font-size: calc(max(min(20vw, 160px), 80px));
  line-height: 0.8;
  color: $purple-light;
}

.secondary {
  position: absolute;
  top: calc(max(min(11.2vw, 90px), 45px));
  left: calc(max(min(14.7vw, 118px), 59px));
  font-family: "Fatya";
  font-size: calc(max(min(12.5vw, 100px), 50px));
  line-height: 0.8;
  transform: rotate(-20deg);
  pointer-events: all;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.backgroundline {
  color: $gray;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.forgroundline {
  color: $white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-shadow:
    0 0 6px $blue,
    0 0 12px $blue,
    0 0 24px $blue-light,
    0 0 48px $white;
}

.neon {
  span {
    opacity: 0;
  }
  span:nth-child(1) {
    animation:
      anim1 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3s infinite;
  }
  span:nth-child(2) {
    animation:
      anim2 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.1s infinite;
  }
  span:nth-child(3) {
    animation:
      anim3 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.2s infinite;
  }
  span:nth-child(4) {
    animation:
      anim4 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.3s infinite;
  }
  span:nth-child(5) {
    animation:
      anim5 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.4s infinite;
  }
  span:nth-child(6) {
    animation:
      anim6 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.5s infinite;
  }
  span:nth-child(8) {
    animation:
      anim7 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.6s infinite;
  }
  span:nth-child(9) {
    animation:
      anim8 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.7s infinite;
  }
  span:nth-child(10) {
    animation:
      anim9 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.8s infinite;
  }
  span:nth-child(11) {
    animation:
      anim10 1s step-end 1.5s 1 normal forwards,
      flicker 0.1s step-start 3.9s infinite;
  }
}

.flicker {
  animation: flicker 0.1s step-start 5s infinite;
}

@keyframes flicker {
  50% { opacity: 0.7; }
  100% { opacity: 0.75; }
}

@keyframes anim1 {
  1% { opacity: 1; }
  50%,100% { opacity: 0; }
}
@keyframes anim2 {
  1% { opacity: 1; }
  90%,100% { opacity: 0; }
}
@keyframes anim3 {
  1% { opacity: 1; }
  60%,100% { opacity: 0; }
}
@keyframes anim4 {
  1% { opacity: 1; }
  75%,100% { opacity: 0; }
}
@keyframes anim5 {
  1% { opacity: 1; }
  95%,100% { opacity: 0; }
}
@keyframes anim6 {
  1% { opacity: 1; }
  65%,100% { opacity: 0; }
}
@keyframes anim7 {
  1% { opacity: 1; }
  80%,100% { opacity: 0; }
}
@keyframes anim8 {
  1% { opacity: 1; }
  85%,100% { opacity: 0; }
}
@keyframes anim9 {
  1% { opacity: 1; }
  55%,100% { opacity: 0; }
}
@keyframes anim10 {
  1% { opacity: 1; }
  70%,100% { opacity: 0; }
}
