@import '../../../styles/colors';

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  max-width: 100%;
  pointer-events: none;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none;
  }
}

.suspense-loader {
  position: absolute;
  top: calc(50% - 64px);
  left: calc(50% - 64px);
}

.nav {
  position: absolute;
  right: 0;
  margin: 0 20px 0 0;
  z-index: 2;
}

.logo {
  position: absolute;
  left: 0;
  z-index: 3;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 5000ms;
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  opacity: 1;
}

.slide-exit-active {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  opacity: 1;
}
