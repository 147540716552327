@font-face {
  font-family: 'Neo-Navy 3D';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/neonavy3d.woff2') format('woff2'),
    url('../fonts/neonavy3d.woff') format('woff');
}

@font-face {
  font-family: 'Oswald Light';
  font-style: normal;
  font-weight: normal;
  src: local('Oswald Light'),
    url('../fonts/oswald.woff2') format('woff2'),
    url('../fonts/oswald.woff') format('woff');
}

@font-face {
  font-family: 'Fatya';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/fatya.woff2') format('woff2'),
    url('../fonts/fatya.woff') format('woff');
}

@font-face {
  font-family: 'SuperPlumberBrothers';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/smb.woff2') format('woff2'),
    url('../fonts/smb.woff') format('woff');
}
