@import '../../../styles/colors';

.menu {
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: -100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    color: #fafafa;
    transition: top 0.3s ease;
    z-index: 2;
    align-items: flex-end;
    padding-top: 60px;
    font-size: 20px;
    pointer-events: all;
    cursor: default;
    outline: none;
  }
}

.menuactive {
  top: 0;
}

.menubutton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;

  @media only screen and (min-width: 600px) {
    display: none;
  }
}
