@import '../../../../styles/colors';

.container {
  position: relative;
  list-style-type: none;
}

.option {
  button {
    font-style: italic;
    color: $blue-light;
  }
}
