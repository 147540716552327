@import '../../../styles/colors';
@import '../../../styles/mixins';

.container {
  position: relative;
  margin: 0 auto;
  max-width: calc(min(85vw, 1000px));
  padding-top: calc(max(min(20vw, 180px), 100px));
  padding-bottom: 40px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.centered {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  min-height: 100%;
}

.full {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 100vw;
}

.sidebar {
  position: relative;
  width: 100%;
  height: auto;

  @media only screen and (min-width: 600px) {
    position: fixed;
    width: 160px;
    height: 80%;
    z-index: 2;
    padding-right: 24px;
    border-right: 1px solid $blue-light;
    @include scrollable();
    @include hideScrollbars();
  }
}

.content {
  @media only screen and (min-width: 600px) {
    margin-left: 160px;
    padding-left: 24px;
  }
}
