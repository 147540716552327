@import '../../../styles/colors';

.container {
  position: relative;
  display: inline-block;
  margin: 12px 16px;
  pointer-events: all;
  text-align: center;
}
.container-left, .container-right {
  margin: 16px 0;
}
.container-left {
  text-align: left;
}
.container-right {
  text-align: right;
}
.block {
  display: block;
}

.label {
  color: $blue-light;
  text-transform: uppercase;
}

.active {
  color: $white;
}

.activebar {
  position: absolute;
  width: 100%;
  height: 2px;
  margin-top: 2px;
  background-color: $blue;
  animation: animGrow 0.3s ease-out;
  transform-origin: center;
}
.activebar-left {
  transform-origin: left;
}
.activebar-right {
  transform-origin: right;
}

@keyframes animGrow {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
