@import '../../../styles/colors';

.loader {
  position: fixed;
  top: calc(50% - 128px);
  left: calc(50% - 128px);
  transition: opacity 300ms;
}

.container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  filter: brightness(40%) blur(8px);
}

.fmv {
  background-color: transparent !important;
  width: 177.77777778vh;
  height: 100%;
  min-width: 100%;
  min-height: 56.25vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1s;
}

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 250ms;
}
