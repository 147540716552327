@import './colors';
@import './fonts';

html,
body {
  height: 100%;
}
body {
  background-color: $black;
  font-family: 'Oswald Light', roboto, sans serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4;
  color: $white;
}
p {
  margin: 18px 0;
}
a:link {
  color: $blue;
  font-weight:bold;
}
a:visited {
  color: $blue;
  font-weight:bold;
}
a:hover {
  color: $purple-light;
  font-weight:bold;
}
:global {
  #app {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
