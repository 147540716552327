.container {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.header {
  align-self: center;
}

.title {
  font-family: 'SuperPlumberBrothers';
  font-size: 96px;
  line-height: 1.05;
}

.subtitle {
  font-size: 32px;
}

.description {
  text-align: left;
}

.smbcanvas {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
  border: 1px solid #555;
}

.loadgame {
  align-self: center;
  white-space: nowrap;
}
