@import '../../../styles/colors';

.container {
  width: 100%;
  padding: 0 16px;
  text-align: center;
  pointer-events: none;

  @media only screen and (min-width: 600px) {
    text-align: right;
    padding: 4px 20px;
  }
}

.fixed {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    pointer-events: none;
  }
}
