.enter {
  opacity: 0;
}

.enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.enter-done {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.exit-done {
  opacity: 0;
}

.container {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translateZ(0);
  background-color: rgba(0,0,0,0.85);
  z-index: 100;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  position: relative;
  padding: 52px;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
