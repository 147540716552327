@import '../../styles/colors.scss';

.title {
  font-size: calc(min(30vw, 144px));
  font-weight: 700;
  color: $gray-dark;
}

.subtitle {
  font-size: calc(min(4vw, 18px));
  color: $gray-light;
  padding-bottom: 40px;
}

.hero {
  margin: 20px 40px;
  max-width: 100%;
}
